/** @jsx jsx */
import { css } from "@emotion/react";

export const politicaPage = css`
  position: relative;
  padding: 120px 0;
  background: #C2F57E;
  font-family: 'Montserrat', sans-serif;
  font-variation-settings: "wght" 700;
  h2 {
    color: #33535E;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  h3 {
    color: #33535E;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
    font-style: normal;
    line-height: 120%;
    margin-bottom: 16px;
    color: #33535E;
  }
`
